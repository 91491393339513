import { ModalCommonProps } from "./types";
import { Button } from "src/designsystem/Button/Button";
import {
  ButtonGroup,
  StyledModal,
  WorkplaceContractFormModalTitle,
  WorkplaceContractFormModalHelpText,
} from "./styles";

function WorkplaceSignContractSuspiciousUser({ closeModal, onSubmit }: ModalCommonProps) {
  const handleOk = () => {
    onSubmit();
    closeModal();
  };

  return (
    <StyledModal visible footer={null} onCancel={closeModal} cancelText={null}>
      <WorkplaceContractFormModalTitle>
        We need to verify your account
      </WorkplaceContractFormModalTitle>
      <WorkplaceContractFormModalHelpText>
        To proceed with your request, we need to verify your account. A member of our team will be
        getting in touch shortly via your workplace's listed phone number.
      </WorkplaceContractFormModalHelpText>
      <ButtonGroup>
        <Button variant="primary" onClick={handleOk}>
          OK
        </Button>
      </ButtonGroup>
    </StyledModal>
  );
}

export { WorkplaceSignContractSuspiciousUser };
