import { Box, Button, Stack } from "@mui/material";
import { ChatChannel } from "../chat.types";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { getLocation } from "@src/utils/routes";
import { Text, useModalState } from "@clipboard-health/ui-react";
import { PlacementDialog } from "@src/appV2/Placements/components/PlacementDialog";

interface PlacementChatHeaderProps {
  currentChannel: ChatChannel;
  backAction?: boolean;
}

export function PlacementChatHeader(props: PlacementChatHeaderProps) {
  const { currentChannel, backAction } = props;
  const placementListingModalState = useModalState();
  const history = useHistory();
  const channelMetadata = currentChannel.metadata;
  return (
    <>
      <Box className="custom-header" sx={{ width: "100%" }}>
        <Box className="custom-header-title" sx={{ width: "100%" }}>
          {backAction && (
            <ArrowLeftOutlined
              className="close-channel"
              onClick={() => history.push(getLocation("facilityChat"))}
            />
          )}

          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            justifyContent="space-between"
            sx={{ width: "100%" }}
          >
            <Stack direction="column" alignItems="flex-start" spacing={1}>
              <Text variant="body1" color="text.primary" bold>
                {[channelMetadata?.hcpName, channelMetadata?.agentReq].filter(Boolean).join(", ")}
              </Text>
              <Text variant="body1" color="text.primary">
                Job listing: {channelMetadata?.placementTitle}
              </Text>
            </Stack>
            <Stack direction="row" alignItems="flex-start" gap={1} flexWrap="wrap">
              {channelMetadata?.placementCandidateAccessId && (
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => {
                    window.open(
                      `/workplace/hire-professionals/${channelMetadata.placementCandidateAccessId}?noScroll=true`,
                      "_blank"
                    );
                  }}
                >
                  <Text variant="caption" color={(theme) => theme.palette.common.white}>
                    View candidate profile
                  </Text>
                </Button>
              )}
              <Button
                variant="outlined"
                size="large"
                onClick={placementListingModalState.openModal}
              >
                <Text variant="caption" color={(theme) => theme.palette.text.primary}>
                  View listing
                </Text>
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Box>
      {placementListingModalState.modalIsOpen && channelMetadata?.placementId && (
        <PlacementDialog
          modalState={placementListingModalState}
          placementId={channelMetadata?.placementId}
        />
      )}
    </>
  );
}
