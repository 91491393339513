import { useState } from "react";
import { Stack, Step, StepLabel, Stepper } from "@mui/material";
import { type ShiftInviteDialogProps } from "../Dialogs";
import { isDefined } from "@clipboard-health/util-ts";
import { ShiftInviteNearbyWorkers } from "../Autocomplete";
import { NearbyWorkerSearchResult } from "../Autocomplete/useGetNearbyWorkers";
import { ShiftInviteReview } from "../ShiftInviteReview";
import { GroupShiftInviteDetails } from "../GroupShiftInviteDetail";
import { useShiftInviteContext } from "../Context/shiftInviteContext";
import { ShiftName } from "@src/interface";
import { utcToZonedTime } from "date-fns-tz";
import { isAfter, startOfDay } from "date-fns";

export interface ShiftInviteStepperProps extends ShiftInviteDialogProps {
  onActiveStepChange?: (step: number) => void;
}

export function ShiftInviteStepper(props: ShiftInviteStepperProps) {
  const {
    workerType,
    modalState,
    initialActiveState = 0,
    initialSelectedWorker,
    onActiveStepChange,
  } = props;
  const [activeStep, setActiveStepState] = useState(initialActiveState);
  const [selectedWorker, setSelectedWorker] = useState<NearbyWorkerSearchResult | undefined>(
    initialSelectedWorker
  );
  const { validateAndUpdateShiftInvite, facilityTimeZone } = useShiftInviteContext();
  const zoneShiftStartTime = utcToZonedTime(props.shiftInfo.start, facilityTimeZone);
  const nowInFacilityTimeZone = utcToZonedTime(new Date(), facilityTimeZone);
  const selectedShiftStartsInFuture =
    isDefined(zoneShiftStartTime) && isAfter(zoneShiftStartTime, nowInFacilityTimeZone);

  const setActiveStep = (step: number) => {
    setActiveStepState(step);
    onActiveStepChange?.(step);
  };

  return (
    <Stack spacing={2}>
      <Stepper activeStep={activeStep}>
        <Step>
          <StepLabel>
            {isDefined(selectedWorker)
              ? `Inviting ${selectedWorker.fullName}, ${workerType}`
              : "Select a Professional"}
          </StepLabel>
        </Step>
        <Step>
          <StepLabel>Select Shift(s)</StepLabel>
        </Step>
        <Step>
          <StepLabel>Review Invites</StepLabel>
        </Step>
      </Stepper>
      {(activeStep === 0 || !isDefined(selectedWorker)) && (
        <ShiftInviteNearbyWorkers
          selectedWorker={selectedWorker}
          setSelectedWorker={setSelectedWorker}
          workerType={workerType}
          setActiveStep={setActiveStep}
          modalState={modalState}
          onClickNext={() => {
            if (selectedShiftStartsInFuture) {
              validateAndUpdateShiftInvite({
                selectedShiftInfo: {
                  start: zoneShiftStartTime,
                  end: utcToZonedTime(props.shiftInfo.end, facilityTimeZone),
                  shiftType: props.shiftInfo.shiftType as ShiftName,
                  isSelected: true,
                },
                selectedDate: startOfDay(zoneShiftStartTime),
                selectedWorker,
              });
            }
          }}
        ></ShiftInviteNearbyWorkers>
      )}
      {activeStep === 1 && (
        <GroupShiftInviteDetails
          selectedWorker={selectedWorker}
          setSelectedWorker={setSelectedWorker}
          setActiveStep={setActiveStep}
          shiftInviteDialogProps={props}
        />
      )}
      {activeStep === 2 && (
        <ShiftInviteReview
          modalState={modalState}
          workerType={workerType}
          setActiveStep={setActiveStep}
          selectedWorker={selectedWorker}
        ></ShiftInviteReview>
      )}
    </Stack>
  );
}
