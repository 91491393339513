import { Chip, Stack } from "@mui/material";
import { CustomTypesChannelFilter } from "../channel";

interface ChatChannelTypeFilterProps {
  customTypesFilter: CustomTypesChannelFilter;
  onFilterChange: (filterValue: CustomTypesChannelFilter) => void;
}

export function ChatChannelTypeFilter({
  customTypesFilter,
  onFilterChange,
}: ChatChannelTypeFilterProps) {
  return (
    <Stack direction="row" spacing={1} sx={{ padding: 1 }}>
      <Chip
        label="All"
        variant="outlined"
        color={customTypesFilter === "all" ? "primary" : "default"}
        clickable
        onClick={() => {
          onFilterChange("all");
        }}
      />
      <Chip
        label="Flex"
        variant="outlined"
        color={customTypesFilter === "flex" ? "primary" : "default"}
        clickable
        onClick={() => {
          onFilterChange("flex");
        }}
      />
      <Chip
        label="Permanent Hiring"
        variant="outlined"
        color={customTypesFilter === "placements" ? "primary" : "default"}
        clickable
        onClick={() => {
          onFilterChange("placements");
        }}
      />
    </Stack>
  );
}
