import { environmentConfig } from "@src/appV2/environment";
import { CbhFeatureFlag } from "@src/appV2/FeatureFlags";
import { FacilityNotificationAlertType } from "../containers/facilityUser/helpers";

enum FACILITY_ROLES {
  ADMIN = "ADM",
  SHIFT_MANAGEMENT = "SFT",
  INVOICES = "INV",
  DOCUMENTS = "DMT",
  RECRUITMENT = "REC",
}

const FACILITY_USER_PERMISSIONS = {
  POST_SHIFT_PERMISSION: "post-shifts",
  CANCEL_SHIFT_PERMISSION: "cancel-shifts",
  MANAGE_INVOICES: "manage-invoices",
  ACCESS_UPCOMING_CHARGES: "access-upcoming-charges",
  ACCESS_CONTRACT_TERMS: "access-contract-terms",
  SEE_CHARGE_RATES: "see-charge-rates",
  DISPUTE_UPCOMING_CHARGES: "dispute-upcoming-charges",
  EDIT_SHIFT_RATE: "edit-shift-rate",
  REVIEW_EXTRA_TIME_PAYMENT_REQUESTS: "review-extra-time-payment-requests",
};

// facility role to user facing value mapping
// in db we are using the same roles
// but changing the user facing value.
const FACILITY_ROLE_MAPPING = new Map([
  ["ADMIN", "ADMIN"],
  ["SHIFT_MANAGEMENT", "SHIFT_MANAGEMENT"],
  ["DOCUMENTS", "SUPERVISOR"],
  ["INVOICES", "INVOICES"],
  ["RECRUITMENT", "RECRUITMENT"],
]);

export enum ALERT_TYPE {
  EMAIL = "EMAIL",
  SMS = "SMS",
}

const CHARGE_RATE_VISIBLE_EMAIL = "CHARGE_RATE_VISIBLE_EMAIL";
const WORKER_ETA_SMS = "WORKER_ETA_SMS";

export type WorkplaceNotificationRoleAndAlerts = Record<
  string,
  {
    lddFlag?: CbhFeatureFlag;
    mandatoryRoles?: FACILITY_ROLES[];
    alertTypes: FacilityNotificationAlertType[];
    type?: ALERT_TYPE;
  }
>;

export type WorkplaceNotificationsMap = Record<ALERT_TYPE, WorkplaceNotificationRoleAndAlerts>;

function getDefaultWorkplaceNotifications(
  addExtraSupervisorPermissions: boolean
): WorkplaceNotificationsMap {
  return {
    EMAIL: {
      INV_EMAIL: {
        mandatoryRoles: [FACILITY_ROLES.INVOICES, FACILITY_ROLES.ADMIN],
        alertTypes: [
          {
            default: {
              enabled: true,
              batch: false,
              userModifiableBatch: false,
              userModifiableState: false,
              time: 16,
            },
            type: ALERT_TYPE.EMAIL,
            title: "Invoice Email",
            action: "invoiceEmail",
            role: "INV_EMAIL",
            mandatoryRoles: [FACILITY_ROLES.INVOICES, FACILITY_ROLES.ADMIN],
            mandatoryPermissions: [FACILITY_USER_PERMISSIONS.MANAGE_INVOICES],
            validRoles: [FACILITY_ROLES.INVOICES, FACILITY_ROLES.ADMIN],
            validPermissions: [FACILITY_USER_PERMISSIONS.MANAGE_INVOICES],
          },
        ],
        type: ALERT_TYPE.EMAIL,
      },
      SFT_EMAIL: {
        alertTypes: [
          {
            default: {
              enabled: true,
              notifyRole: "SFT_EMAIL",
              userModifiableBatch: true,
              userModifiableState: true,
              batch: false,
              time: 16,
            },
            type: ALERT_TYPE.EMAIL,
            title: "Shift Assignment",
            action: "assignmentEmail",
            role: "SFT_EMAIL",
            validRoles: [
              FACILITY_ROLES.SHIFT_MANAGEMENT,
              FACILITY_ROLES.ADMIN,
              ...(addExtraSupervisorPermissions ? [FACILITY_ROLES.DOCUMENTS] : []),
            ],
            validPermissions: [FACILITY_USER_PERMISSIONS.POST_SHIFT_PERMISSION],
          },
          {
            default: {
              enabled: true,
              notifyRole: "SFT_EMAIL",
              userModifiableBatch: true,
              userModifiableState: true,
              batch: false,
              time: 16,
            },
            type: ALERT_TYPE.EMAIL,
            title: "Shift Unassign",
            action: "shiftUnassignmentEmail",
            role: "SFT_EMAIL",
            validRoles: [
              FACILITY_ROLES.SHIFT_MANAGEMENT,
              FACILITY_ROLES.ADMIN,
              ...(addExtraSupervisorPermissions ? [FACILITY_ROLES.DOCUMENTS] : []),
            ],
            validPermissions: [FACILITY_USER_PERMISSIONS.CANCEL_SHIFT_PERMISSION],
          },
          {
            default: {
              enabled: true,
              notifyRole: "SFT_EMAIL",
              batch: false,
              userModifiableBatch: false,
              userModifiableState: true,
              time: 16,
            },
            type: ALERT_TYPE.EMAIL,
            title: "Workplace Staffing Summary",
            action: "staffingSummary",
            role: "SFT_EMAIL",
            validRoles: [FACILITY_ROLES.SHIFT_MANAGEMENT, FACILITY_ROLES.ADMIN],
          },
          {
            default: {
              enabled: true,
              notifyRole: "SFT_EMAIL",
              userModifiableBatch: false,
              userModifiableState: true,
              batch: false,
              time: 16,
            },
            type: ALERT_TYPE.EMAIL,
            title: "Clock-In Method Change",
            action: "clockInMethodChangeEmail",
            role: "SFT_EMAIL",
            validRoles: [FACILITY_ROLES.SHIFT_MANAGEMENT, FACILITY_ROLES.ADMIN],
          },
          {
            default: {
              enabled: true,
              notifyRole: "SFT_EMAIL",
              userModifiableBatch: true,
              userModifiableState: true,
              batch: false,
              time: 16,
            },
            type: ALERT_TYPE.EMAIL,
            title: "Rate Negotiation",
            action: "rateNegotiationEmail",
            role: "SFT_EMAIL",
            validRoles: [FACILITY_ROLES.SHIFT_MANAGEMENT, FACILITY_ROLES.ADMIN],
            lddFlag: CbhFeatureFlag.RATE_NEGOTIATION_COMMS_EMAIL,
          },
          {
            default: {
              enabled: true,
              notifyRole: "SFT_EMAIL",
              batch: false,
              userModifiableBatch: false,
              userModifiableState: true,
              time: 16,
            },
            type: ALERT_TYPE.EMAIL,
            title: "Worked Break Payment Request",
            action: "ewtrEmails",
            role: "SFT_EMAIL",
            validRoles: [FACILITY_ROLES.ADMIN, FACILITY_ROLES.INVOICES],
            validPermissions: [FACILITY_USER_PERMISSIONS.REVIEW_EXTRA_TIME_PAYMENT_REQUESTS],
            lddFlag: CbhFeatureFlag.SOLVE_UNPAID_BREAKS_ROLLOUT_STAGE_WITH_TARGETING,
          },
        ],
        type: ALERT_TYPE.EMAIL,
      },
      VERIFIED_SUMMARY_EMAIL: {
        alertTypes: [
          {
            disableBatching: true,
            mandatoryRoles: [FACILITY_ROLES.ADMIN],
            default: {
              enabled: true,
              userModifiableBatch: false,
              userModifiableState: true,
              batch: false,
              time: 16,
            },
            type: ALERT_TYPE.EMAIL,
            title: "Weekly Verified Shifts Summary",
            action: "weeklyVerifiedShiftsSummary",
            role: "VERIFIED_SUMMARY_EMAIL",
            validRoles: [
              FACILITY_ROLES.SHIFT_MANAGEMENT,
              FACILITY_ROLES.INVOICES,
              FACILITY_ROLES.DOCUMENTS,
              FACILITY_ROLES.ADMIN,
            ],
          },
        ],
        type: ALERT_TYPE.EMAIL,
      },
      CHARGE_RATE_VISIBLE_EMAIL: {
        alertTypes: [
          {
            disableBatching: true,
            default: {
              enabled: true,
              userModifiableBatch: false,
              userModifiableState: true,
              batch: false,
              time: 16,
            },
            type: ALERT_TYPE.EMAIL,
            title: "Charge Rates Visibility",
            action: "chargeRateVisibility",
            role: CHARGE_RATE_VISIBLE_EMAIL,
            validRoles: [FACILITY_ROLES.SHIFT_MANAGEMENT, FACILITY_ROLES.ADMIN],
          },
        ],
        type: ALERT_TYPE.EMAIL,
      },
      CDPH530_DAILY_EMAIL: {
        lddFlag: CbhFeatureFlag.CALIFORNIA_TIMESHEET,
        alertTypes: [
          {
            disableBatching: true,
            default: {
              enabled: true,
              userModifiableBatch: false,
              userModifiableState: true,
              batch: false,
              time: 16,
            },
            type: ALERT_TYPE.EMAIL,
            title: "Daily CDPH-530 Recap",
            action: "dailyCDPH530Recap",
            role: "CDPH530_DAILY_EMAIL",
            validRoles: [FACILITY_ROLES.ADMIN, FACILITY_ROLES.SHIFT_MANAGEMENT],
          },
        ],
        type: ALERT_TYPE.EMAIL,
      },
      REC_EMAIL: {
        lddFlag: CbhFeatureFlag.PLACEMENTS_ACCESS_CONTROL_ROLLOUT,
        type: ALERT_TYPE.EMAIL,
        alertTypes: [
          {
            default: {
              enabled: true,
              userModifiableBatch: false,
              userModifiableState: true,
              batch: false,
              time: 16,
            },
            type: ALERT_TYPE.EMAIL,
            title: "Candidate Apply",
            action: "candidateApplyEmail",
            role: "REC_EMAIL",
            validRoles: [
              FACILITY_ROLES.ADMIN,
              FACILITY_ROLES.SHIFT_MANAGEMENT,
              FACILITY_ROLES.RECRUITMENT,
            ],
          },
        ],
      },
    },
    SMS: {
      SFT_SMS: {
        alertTypes: [
          {
            default: {
              enabled: true,
              batch: false,
              notifyRole: "SFT_SMS",
              userModifiableBatch: true,
              userModifiableState: true,
              time: 16,
            },
            type: ALERT_TYPE.SMS,
            title: "Shift Assignment SMS",
            action: "assignmentSMS",
            role: "SFT_SMS",
            validRoles: [
              FACILITY_ROLES.SHIFT_MANAGEMENT,
              FACILITY_ROLES.ADMIN,
              ...(addExtraSupervisorPermissions ? [FACILITY_ROLES.DOCUMENTS] : []),
            ],
            validPermissions: [FACILITY_USER_PERMISSIONS.POST_SHIFT_PERMISSION],
          },
          {
            default: {
              enabled: true,
              notifyRole: "SFT_SMS",
              batch: false,
              userModifiableBatch: true,
              userModifiableState: true,
              time: 16,
            },
            type: ALERT_TYPE.SMS,
            title: "Shift Unassign SMS",
            action: "shiftUnassignmentSMS",
            role: "SFT_SMS",
            validRoles: [
              FACILITY_ROLES.SHIFT_MANAGEMENT,
              FACILITY_ROLES.ADMIN,
              ...(addExtraSupervisorPermissions ? [FACILITY_ROLES.DOCUMENTS] : []),
            ],
            validPermissions: [FACILITY_USER_PERMISSIONS.CANCEL_SHIFT_PERMISSION],
          },
          {
            default: {
              enabled: true,
              notifyRole: "SFT_SMS",
              batch: false,
              userModifiableBatch: false,
              userModifiableState: false,
              time: 16,
            },
            type: ALERT_TYPE.SMS,
            title: "Signature Request (By Us)",
            action: "shiftCBHSignatureRequestSMS",
            role: "SFT_SMS",
            validRoles: [FACILITY_ROLES.SHIFT_MANAGEMENT, FACILITY_ROLES.ADMIN],
          },
          {
            default: {
              enabled: true,
              notifyRole: "SFT_SMS",
              batch: false,
              userModifiableBatch: false,
              userModifiableState: true,
              time: 16,
            },
            type: ALERT_TYPE.SMS,
            title: "Chat messages",
            action: "chatMessages",
            role: "SFT_SMS",
            validRoles: [FACILITY_ROLES.SHIFT_MANAGEMENT, FACILITY_ROLES.ADMIN],
          },
          {
            default: {
              enabled: true,
              notifyRole: "SFT_SMS",
              batch: false,
              userModifiableBatch: false,
              userModifiableState: true,
              time: 16,
            },
            type: ALERT_TYPE.SMS,
            title: "Clock-In Method Change",
            action: "clockInMethodChangeSMS",
            role: "SFT_SMS",
            validRoles: [FACILITY_ROLES.SHIFT_MANAGEMENT, FACILITY_ROLES.ADMIN],
          },
          {
            default: {
              enabled: true,
              notifyRole: "SFT_SMS",
              batch: false,
              userModifiableBatch: false,
              userModifiableState: true,
              time: 16,
            },
            type: ALERT_TYPE.SMS,
            title: "Rate Negotiation SMS",
            action: "rateNegotiationSMS",
            role: "SFT_SMS",
            validRoles: [FACILITY_ROLES.SHIFT_MANAGEMENT, FACILITY_ROLES.ADMIN],
            lddFlag: CbhFeatureFlag.RATE_NEGOTIATION_COMMS_SMS,
          },
        ],
        type: ALERT_TYPE.SMS,
      },
      WORKER_ETA_SMS: {
        alertTypes: [
          {
            disableBatching: true,
            default: {
              enabled: true,
              notifyRole: WORKER_ETA_SMS,
              batch: false,
              userModifiableBatch: false,
              userModifiableState: true,
              time: 16,
            },
            type: ALERT_TYPE.SMS,
            title: "Worker ETA",
            action: "workerEtaSMS",
            role: WORKER_ETA_SMS,
            validRoles: [
              FACILITY_ROLES.ADMIN,
              FACILITY_ROLES.DOCUMENTS,
              FACILITY_ROLES.INVOICES,
              FACILITY_ROLES.SHIFT_MANAGEMENT,
            ],
          },
        ],
        type: ALERT_TYPE.SMS,
      },
    },
  };
}

const FACILITY_ROLE_DESCRIPTIONS = {
  [FACILITY_ROLES.ADMIN]:
    "This permission gives the user access to all permissions and additionally gives the user the ability to edit and remove additional users for this facility.",
  [FACILITY_ROLES.SHIFT_MANAGEMENT]:
    "This permission gives the user the ability to request and cancel shifts.",
  [FACILITY_ROLES.DOCUMENTS]:
    "This permission allows the user to view all shifts and worker ETA but does not allow to manage the schedule.",
  [FACILITY_ROLES.INVOICES]:
    "This permission means that this user will receive a copy of the invoice via email.",
  [FACILITY_ROLES.RECRUITMENT]:
    "This permission allows the user to access everything related to recruitment.",
};

const HOLIDAY_INPUT_PROPS = {
  FLAT_PROPS: {
    description: "How much is that flat increase?",
    regex: /\.(.*?[0-9]*)/g,
    label: " dollars per hour",
  },
  MULTIPLIER_PROPS: {
    description: "How much is that Multiplier increase?",
    regex: "/.dd/",
    label: " times the charge rate",
  },
};

const HOLIDAY_DIFFERENTIAL = {
  FLAT: "FLAT",
  CHARGE_RATE_MULTIPLIER: "CHARGE_RATE_MULTIPLIER",
};

const truncateToOneDecimal = (value: any) => {
  const decimalValue = 0.0;
  if (typeof value === "number") {
    return value.toString().match(/^-?\d+(?:\.\d{0,1})?/)?.[0];
  }
  return decimalValue;
};

const RATES_TABLE = {
  sunday: { am: 0, pm: 0, noc: 0 },
  monday: { am: 0, pm: 0, noc: 0 },
  tuesday: { am: 0, pm: 0, noc: 0 },
  wednesday: { am: 0, pm: 0, noc: 0 },
  thursday: { am: 0, pm: 0, noc: 0 },
  friday: { am: 0, pm: 0, noc: 0 },
  saturday: { am: 0, pm: 0, noc: 0 },
};

const PERMISSIONS = {
  NOTES: { NOTE_EDIT: "NOTE_EDIT" },
};

const INSTANT_BOOK_TYPE = {
  ON: "ON",
  OFF: "OFF",
  PAST_HCPS_ONLY: "PAST_HCPS_ONLY",
};

const batchDescription = {
  EMAIL: "Batch all emails in the preceding 24 hours and send to me at this time",
  SMS: "Batch all Text Messages in the preceding 24 hours and send to me at this time",
};

const hours = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const FACILITY_PREFERENCE_BASE_URL = `${environmentConfig.REACT_APP_SHIFT_VERIFICATION_BASE_URL}/facilities`;

const FACILITY_HELP_SUPPORT_LINK =
  environmentConfig.REACT_APP_FACILITY_SUPPORT_ARTICLES_LINK ||
  "https://support.clipboardhealth.com/hc/en-us/categories/4943215926167-Facilities";

const DEFAULT_BASE_CHARGE_LIMIT = 250;

export {
  FACILITY_ROLES,
  FACILITY_ROLE_MAPPING,
  INSTANT_BOOK_TYPE,
  getDefaultWorkplaceNotifications,
  FACILITY_ROLE_DESCRIPTIONS,
  HOLIDAY_DIFFERENTIAL,
  HOLIDAY_INPUT_PROPS,
  truncateToOneDecimal,
  RATES_TABLE,
  PERMISSIONS,
  batchDescription,
  hours,
  FACILITY_PREFERENCE_BASE_URL,
  CHARGE_RATE_VISIBLE_EMAIL,
  WORKER_ETA_SMS,
  FACILITY_HELP_SUPPORT_LINK,
  FACILITY_USER_PERMISSIONS,
  DEFAULT_BASE_CHARGE_LIMIT,
};
