import { useModalState } from "@clipboard-health/ui-react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { useEffect } from "react";
import { useLocalStorage } from "usehooks-ts";

const appVersion = __APP_VERSION__;

export function NewWebVersionDialog() {
  const modalState = useModalState();
  const appVersionUpdateControl = useCbhFlag(CbhFeatureFlag.APP_VERSION_UPDATE_CONTROL, {
    defaultValue: "off",
  });
  const [dismissedAppVersionUpdate, setDismissedAppVersionUpdate] = useLocalStorage<
    Record<string, boolean>
  >("DISMISSED_APP_VERSION_UPDATE", {});

  useEffect(() => {
    if (dismissedAppVersionUpdate[appVersion] || appVersionUpdateControl === "off") {
      modalState.closeModal();
    } else if (
      appVersionUpdateControl === "force-update" ||
      appVersionUpdateControl === "soft-update"
    ) {
      modalState.openModal();
    }
    // modalState creates a new instance on each render, we shouldn’t include it in the dependencies
    // https://github.com/ClipboardHealth/cbh-core/blob/main/packages/ui-react/src/Modals/modalState/useModalState.ts#L17
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appVersionUpdateControl, dismissedAppVersionUpdate]);

  const closeDialog = () => {
    setDismissedAppVersionUpdate({ ...dismissedAppVersionUpdate, [appVersion]: true });
    modalState.closeModal();
  };

  return (
    <Dialog
      open={modalState.modalIsOpen}
      onClose={modalState.closeModal}
      maxWidth="md"
      disableEscapeKeyDown
      fullWidth
      scroll="paper"
    >
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          New Version Available
          {appVersionUpdateControl === "soft-update" ? (
            <IconButton aria-label="close" onClick={closeDialog}>
              <CloseIcon />
            </IconButton>
          ) : undefined}
        </Stack>
      </DialogTitle>
      <DialogContent dividers>
        A newer version of the app is now available. To ensure you have the latest features and
        updates, please refresh the page.
      </DialogContent>
      <DialogActions>
        {appVersionUpdateControl === "soft-update" ? (
          <Button variant="outlined" onClick={closeDialog}>
            Close
          </Button>
        ) : undefined}
        <Button
          type="submit"
          variant="contained"
          onClick={() => {
            setDismissedAppVersionUpdate({ ...dismissedAppVersionUpdate, [appVersion]: true });
            location.reload();
          }}
        >
          Refresh
        </Button>
      </DialogActions>
    </Dialog>
  );
}
