import { formatCentsAsUsd } from "@src/appV2/lib/Money/utils/currency";
import { PlacementWithDetails } from "../api/useFetchPaginatedPlacements";

export function getStatusColor(status: string) {
  switch (status.toLowerCase()) {
    case "open":
      return "success";
    case "draft":
      return "default";
    case "archived":
    case "closed":
      return "warning";
    default:
      return "default";
  }
}

export function formatPayRange(payRange: PlacementWithDetails["payRange"]) {
  const minPay = payRange?.minPay?.amountInMinorUnits;
  const maxPay = payRange?.maxPay?.amountInMinorUnits;

  if (!minPay && !maxPay) {
    return "Pay range: N/A";
  }
  if (minPay && maxPay) {
    return `Pay range: ${formatCentsAsUsd(minPay)} /hr - ${formatCentsAsUsd(maxPay)} /hr`;
  }
  return `Pay: ${formatCentsAsUsd(minPay || maxPay || 0)} /hr`;
}
