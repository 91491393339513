import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import {
  PlacementResponse,
  PlacementResponseSchema,
} from "@src/appV2/Placements/types/fetchPlacements.schema";

interface GetPlacementParams {
  placementId: string;
  workplaceId: string;
}

export function useGetPlacement(
  params: GetPlacementParams,
  options?: UseGetQueryOptions<PlacementResponse>
) {
  const { placementId, workplaceId } = params;
  return useGetQuery({
    url: `${environmentConfig.REACT_APP_BASE_API_URL}/workplaces/${workplaceId}/placements/${placementId}`,
    responseSchema: PlacementResponseSchema,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_PLACEMENT_FAILURE,
      userErrorMessage: "Something went wrong while loading the listing.",
    },
    ...options,
  });
}
