import { logEvent } from "@src/appV2/lib/analytics";
import { HCF_USER_EVENTS } from "src/constants/firebaseEvents";
import {
  DnrAction,
  WhoCalledRatingPerformanceModal,
  RatingPerformanceState,
} from "./workerReview.types";
import { capitalizeFirstLetter } from "src/utils/string";
import { ExclusionForList } from "../../api/workerReview";
import { isDefined } from "@clipboard-health/util-ts";

const WORKER_REVIEW_QUALITY_OPTIONS_RATING_GREATER_THAN_THREE = [
  "Is punctual",
  "Completes all charting",
  "Handles patients well",
  "Is professional",
  "Has a great attitude",
  "Is a quick learner",
];

const WORKER_REVIEW_QUALITY_OPTIONS_FOR_RATING_LESS_THAN_THREE = [
  "Is not punctual",
  "Does not chart",
  "Lacks training",
  "Rude",
  "Mishandles patients",
  "Refuses tasks",
  "Unprofessional",
];

export const WORKER_REVIEW_DNR_REASON = "DNR Added by Workplace via the Shift Review Form";

const RATING_THREE = 3;

export const workerReviewRatingQualityOptions = (
  rating?: number,
  options: Record<string, string[]> = {}
) => {
  if (typeof rating !== "number") {
    return [];
  }

  if (isDefined(options[rating])) {
    return options[rating];
  }

  if (rating <= RATING_THREE) {
    return WORKER_REVIEW_QUALITY_OPTIONS_FOR_RATING_LESS_THAN_THREE;
  } else if (rating > RATING_THREE) {
    return WORKER_REVIEW_QUALITY_OPTIONS_RATING_GREATER_THAN_THREE;
  }
  return [];
};

// We want to use the present tense for several qualities below
// due to recent changes, those qualities are removed
// for backward compatibility, we have a special mapping for those removed ones
export const getNameFromLegacyQuality = (quality: string): string => {
  const legacyQuanlities = {
    "Arrived on time": "Arrives on time",
    "Completed all tasks": "Completes all tasks",
    "Inappropriately dressed": "Dresses inappropriately",
    "Mistreated staff / patients": "Mistreats staff / patients",
    "Arrived late / left early": "Arrives late / leaves early",
    "Did not complete all tasks": "Does not complete all tasks",
  };

  return legacyQuanlities[quality] ?? quality;
};

const buildObjectWithHistoricValues = (propertyName, currentValue, initialValue) => {
  const object = { [propertyName]: currentValue[propertyName] };
  if (initialValue?.[propertyName] !== currentValue[propertyName]) {
    return {
      ...object,
      [`previous${capitalizeFirstLetter(propertyName)}`]: initialValue?.[propertyName],
    };
  }
  return object;
};

/**
 * We are triggering same event thrice in the code, so moving it here to avoid duplication.
 */
export const captureWorkerReviewSubmittedEvent = (
  state: RatingPerformanceState,
  initialState: Partial<RatingPerformanceState>,
  {
    shiftId,
    workerId,
    workplaceId,
    workplaceUserId,
    dnrAction,
    whoCalledMe,
    ...options
  }: {
    shiftId?: string;
    workerId: string;
    workplaceId: string;
    workplaceUserId: string;
    dnrAction?: DnrAction;
    whoCalledMe: WhoCalledRatingPerformanceModal;
  }
) => {
  logEvent(HCF_USER_EVENTS.SHIFT_REVIEW_SUBMITTED, {
    location: whoCalledMe,
    trigger: state.workerReviewId ? "viewOrEditReview" : "addReview",
    shiftId,
    workerId,
    workplaceId,
    workplaceUserId,
    ...buildObjectWithHistoricValues("qualities", state, initialState),
    ...buildObjectWithHistoricValues("additionalFeedback", state, initialState),
    ...buildObjectWithHistoricValues("rating", state, initialState),
    ...buildObjectWithHistoricValues("dnrWorker", state, {
      ...initialState,
      dnrWorker: !!state.exclusionId,
    }),
    isDnrManuallyToggled: state.dnrFieldTouched,
    ...options,
  });
};

export const doesRatingMakeWorkerFavorite = (rating?: number) =>
  rating !== undefined && rating > RATING_THREE;

export function getDNRAction(
  dnrWorker: boolean | undefined,
  exclusion: ExclusionForList | undefined
): DnrAction | undefined {
  if (dnrWorker === undefined) {
    return undefined;
  }

  if (dnrWorker && !exclusion) {
    return DnrAction.CREATE;
  } else if (!dnrWorker && exclusion) {
    return DnrAction.DELETE;
  }
}
