import React, { useEffect } from "react";
import { Form, Row, Button, PageHeader, Divider, Typography, Spin, Alert } from "antd";
import { randomPhoneNumber } from "@clipboard-health/testing";
import axios from "axios";
import "../css/create_hcf.css";
import { CREATE_HCF_AND_SHIFT_DEFAULT_YAML_VALUE } from "../constants/createHcfsAndShifts";
import yaml from "yaml";
import MonacoEditor from "react-monaco-editor";
import ShouldRender from "./ShouldRender";
import moment from "moment";
import { environmentConfig } from "@src/appV2/environment";
import { showErrorToast, showSuccessToast } from "@src/appV2/lib/Notifications";

const { Title } = Typography;

function CreateHcfsAndShifts(props: any) {
  const { selectedRegressionTestData = {} } = props;
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();
  const [HCFAndShiftYAMLData, setHCFAndShiftYAMLData] = React.useState(
    CREATE_HCF_AND_SHIFT_DEFAULT_YAML_VALUE
  );

  useEffect(() => {
    if (selectedRegressionTestData.config) {
      const { hcfAndShift: hcfAndShiftData } = yaml.parse(selectedRegressionTestData.config);
      setHCFAndShiftYAMLData(yaml.stringify({ hcfAndShift: hcfAndShiftData }));
    } else if (selectedRegressionTestData.id) {
      setHCFAndShiftYAMLData("hcf:\n");
    }
  }, [selectedRegressionTestData]);

  const editorDidMount = (editor) => {
    // @ts-expect-error Property 'monacoEditor' does not exist on type 'Window & typeof globalThis'.ts(2339)
    if (!window.monacoEditor) {
      Object.defineProperty(window, "monacoEditor", {
        value: editor,
        writable: false,
      });
    }
    editor.focus();
  };

  const options = {
    selectOnLineNumbers: true,
  };

  async function createFacility(data) {
    return await axios.post(
      `${environmentConfig.REACT_APP_API_MAIN_URL || ""}/api/testHelpers/createHcf`,
      data
    );
  }

  async function createShift(data) {
    return await axios.post(
      `${environmentConfig.REACT_APP_API_MAIN_URL || ""}/api/testHelpers/shift/createShift`,
      data
    );
  }

  function addHoursToShiftTimes(shiftData, hours) {
    const shiftStartTimeMoment = moment(shiftData.shift.start).add(hours, "hour");
    const shiftEndTimeMoment = moment(shiftData.shift.end).add(hours, "hour");
    return {
      ...shiftData,
      shift: {
        ...shiftData.shift,
        start: shiftStartTimeMoment,
        end: shiftEndTimeMoment,
      },
    };
  }

  const onSubmit = async () => {
    try {
      setLoading(true);
      const hcfAndShiftData = yaml.parse(HCFAndShiftYAMLData);
      const currentTimestamp = moment().format("YYYY-MM-DD");

      for (let i = 1; i <= hcfAndShiftData.numberOfFacilitiesToCreate; i++) {
        // Generate unique workplace name to identify easily
        // workplace Name format: Test workplace_{n}_{current date YYYY-MM-DD}_{random Number}
        const facilityName = `Test Facility_${i}_${currentTimestamp}_${Math.floor(
          Math.random() * 5000
        )}`;
        const email = facilityName.replace(/\s/g, "");
        hcfAndShiftData.hcf.name = facilityName;
        hcfAndShiftData.hcf.email = `${email}@yopmail.com`;
        hcfAndShiftData.hcf.phone = randomPhoneNumber({ international: false });

        const createFacilityResponse = await createFacility({
          ...hcfAndShiftData.hcf,
        });

        const facilityId = createFacilityResponse.data.facilityObj.userId;

        // Creating Shifts for the newly created facility
        hcfAndShiftData.shiftData.shift.facilityId = facilityId;
        const initialShiftStartTimeMoment = moment(hcfAndShiftData.shiftData.shift.start);
        const initialShiftEndTimeMoment = moment(hcfAndShiftData.shiftData.shift.end);

        for (let i = 1; i <= hcfAndShiftData.numberOfShiftsForEachFacility; i++) {
          const createShiftResponse = await createShift({
            ...hcfAndShiftData.shiftData,
          });

          // Logging it to console as it will be easier to track multiple facilities
          console.log(
            `ShiftId${i}: ${createShiftResponse.data.shift._id} || FacilityId: ${facilityId}`
          );

          // Changing Shift start and end time to create non-identical shift
          hcfAndShiftData.shiftData = addHoursToShiftTimes(hcfAndShiftData.shiftData, 1);
        }

        // Reseting the Shift start and end time to initial time
        hcfAndShiftData.shiftData.shift.start = initialShiftStartTimeMoment;
        hcfAndShiftData.shiftData.shift.end = initialShiftEndTimeMoment;
        console.log("Shift start and end time resetted");
      }

      showSuccessToast("Workplaces and Shifts Are Created Successfully");
    } catch (error) {
      showErrorToast(`Error Creating Workplaces and Shifts - ${(error as Error).message}`);
    } finally {
      setLoading(false);
    }
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  return (
    <Spin spinning={loading}>
      <div>
        <PageHeader
          className="createHCPHeader"
          title="Create Workplaces and Shifts"
          extra={[
            <Button
              type="default"
              key={"key"}
              onClick={() => {
                window.open(`https://dev.to/paulasantamaria/introduction-to-yaml-125f`)?.focus();
              }}
            >
              Intro to YAML
            </Button>,
          ]}
        />
        <Divider className="createHCPHeaderDivider" />
        <Row justify="start" align="middle" style={{ width: "100%", height: "100%" }}>
          <Form {...layout} name="basic" form={form} onFinish={onSubmit} style={{ width: "600px" }}>
            <Row>
              <Title level={4}>YAML Config</Title>
              <div>
                <ShouldRender condition={selectedRegressionTestData.name}>
                  <Alert
                    style={{ marginBottom: ".5rem" }}
                    message={`Showing config data for test - ${selectedRegressionTestData.name}`}
                    type="info"
                    showIcon
                  />
                </ShouldRender>
              </div>
            </Row>
            <Row>
              <MonacoEditor
                height="400"
                language="javascript"
                theme="vs-dark"
                value={HCFAndShiftYAMLData}
                options={options}
                editorDidMount={editorDidMount}
                onChange={(value) => {
                  setHCFAndShiftYAMLData(value);
                }}
              />
            </Row>

            <Row justify="end" align="middle" style={{ marginTop: "1rem" }}>
              <Button type="primary" htmlType="submit" loading={loading}>
                Submit
              </Button>
            </Row>
          </Form>
        </Row>
      </div>
    </Spin>
  );
}

export default CreateHcfsAndShifts;
