import { Button, CardActions, Stack } from "@mui/material";

import { CbhCustomChatMessage } from "@src/appV2/Chat/CbhCustomChatMessage";
import { ChatChannel } from "../chat.types";
import { CoreMessageType } from "@sendbird/uikit-react/types/utils";

interface PlacementApplicationMessageProps {
  message: CoreMessageType;
  currentChannel: ChatChannel;
}

export function PlacementApplicationMessage({
  message,
  currentChannel,
}: PlacementApplicationMessageProps) {
  const { placementCandidateAccessId } = currentChannel.metadata ?? {};
  const chatMessage = message as CoreMessageType & { message: string };
  if (!chatMessage.message) {
    return undefined as unknown as React.ReactElement;
  }
  const { hcpName, agentReq, placementTitle, facilityName } = currentChannel.metadata ?? {};

  const messageToDisplay =
    !hcpName || !placementTitle || !facilityName
      ? chatMessage.message
      : `${[hcpName, agentReq]
          .filter(Boolean)
          .join(
            ", "
          )} has applied for the ${placementTitle} opening at ${facilityName}. View their candidate profile to check their resume, license and pre-employment credentials.`;
  return (
    <CbhCustomChatMessage
      message={messageToDisplay}
      avatarSxProps={{ justifyContent: "flex-start" }}
      messageContainerSxProps={{ width: "50%" }}
    >
      <CardActions>
        <Stack direction="row" spacing={1} justifyContent="flex-end" sx={{ px: 1 }}>
          {placementCandidateAccessId && (
            <Button
              onClick={() => {
                window.open(
                  `/workplace/hire-professionals/${placementCandidateAccessId}?noScroll=true`,
                  "_blank"
                );
              }}
              variant="contained"
            >
              View candidate profile
            </Button>
          )}
        </Stack>
      </CardActions>
    </CbhCustomChatMessage>
  );
}
