import { UseModalState } from "@clipboard-health/ui-react";
import { useGetPlacement } from "../api/useGetPlacement";
import { useSession } from "@src/appV2/Auth/api/useSession";
import {
  Card,
  CardContent,
  Dialog,
  DialogContent,
  IconButton,
  Skeleton,
  Stack,
} from "@mui/material";
import { DialogTitle } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { transformResponseIntoPlacement } from "../api/useFetchPaginatedPlacements";
import { PlacementDetails } from "./PlacementDetails";

interface PlacementDialogProps {
  modalState: UseModalState;
  placementId: string;
}

export function PlacementDialog(props: PlacementDialogProps) {
  const { modalState, placementId } = props;
  const { profile } = useSession();
  const {
    data: rawPlacementResponse,
    isLoading: isLoadingPlacement,
    isSuccess: isPlacementSuccess,
  } = useGetPlacement({
    placementId,
    workplaceId: profile.userId,
  });
  const placement = isPlacementSuccess
    ? transformResponseIntoPlacement(rawPlacementResponse.data, rawPlacementResponse.included)
    : undefined;

  return (
    <Dialog
      open={modalState.modalIsOpen}
      onClose={modalState.closeModal}
      maxWidth="lg"
      fullWidth
      scroll="paper"
    >
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          Listing details
          <IconButton aria-label="close" onClick={modalState.closeModal}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent dividers>
        {isLoadingPlacement && <Skeleton variant="rounded" height={200} />}
        {placement && (
          <Card sx={{ width: "100%" }} variant="outlined">
            <CardContent
              sx={{
                "&:last-child": {
                  paddingBottom: 2,
                },
              }}
            >
              <PlacementDetails placement={placement} />
            </CardContent>
          </Card>
        )}
      </DialogContent>
    </Dialog>
  );
}
