// FIXME: replace the usage of moment with date-fns and re-organize the chat module to have separate files for modals, pages, utility functions under this ticket: https://clipboardhealth.atlassian.net/browse/CH-25409
import moment from "moment-timezone";
import { ExclusionForList } from "src/api/workerReview";
import { FileFilled } from "@ant-design/icons";
import { setChannels } from "src/modules/chat";
import { Dispatch } from "redux";
import { ChatChannel } from "./chat.types";
import { roundUpHoursTwoDecimal } from "src/utils/shifts";
import { SdkStore } from "@sendbird/uikit-react/types/lib/types";
import { GroupChannelListOrder, MyMemberStateFilter } from "@sendbird/chat/groupChannel";
import { isDefined } from "@clipboard-health/util-ts";

const CHAT_CHANNEL_QUERY_LIMIT = 100;

type ChannelStatus =
  | "nothing"
  | "loading"
  | "closed_after_days"
  | "past"
  | "today"
  | "tomorrow"
  | "ahead"
  | "in progress";

export function isChatChannelInActive(lastBookedDate, activeChatWindowDays) {
  const totalDaysDifference = moment().diff(lastBookedDate, "days");
  return lastBookedDate && activeChatWindowDays && totalDaysDifference > activeChatWindowDays;
}

export function getChannelStatus(
  channel?: ChatChannel,
  activeChatWindowDays?: number
): ChannelStatus {
  if (!channel) {
    return "loading";
  }
  if (!channel.shift) {
    // if the feature flag is disabled or there's no metadata
    if (!channel.metadata?.lastBooked) {
      return "nothing";
    }

    const lastBookedDate = moment(channel.metadata.lastBooked);
    if (isChatChannelInActive(lastBookedDate, activeChatWindowDays)) {
      return "closed_after_days";
    } else {
      return "past";
    }
  }

  const start = moment(channel.shift.start);
  const end = moment(channel.shift.end);

  if (
    channel.metadata?.lastBooked &&
    isChatChannelInActive(moment(channel.metadata.lastBooked), activeChatWindowDays)
  ) {
    return "closed_after_days";
  }

  const tomorrowEndOfDay = moment().add(1, "day").endOf("day");
  const todayEndOfDay = moment().endOf("day");

  if (end.isBefore(moment())) {
    return "past";
  }
  if (start.isAfter(moment())) {
    if (start.isBefore(todayEndOfDay)) {
      return "today";
    }
    if (start.isBefore(tomorrowEndOfDay)) {
      return "tomorrow";
    }
    return "ahead";
  }

  return "in progress";
}

export function getChannelDisabledMessage(
  actionBy?: ExclusionForList["actionBy"]
): string | undefined {
  if (actionBy === "AGENT") {
    return "You are blocked by this professional. Chat disabled.";
  } else if (actionBy === "FACILITY") {
    return "You blocked this professional. Chat disabled.";
  }
}

export function getChannelShiftTime(channel?: ChatChannel, activeChatWindowDays?: number) {
  if (!channel) {
    return "Loading...";
  }
  if (channel.customType === "placements") {
    if (channel.metadata?.placementTitle) {
      return `Job listing: ${channel.metadata?.placementTitle}`;
    }
    return <></>;
  }
  if (!channel.shift) {
    return "No upcoming shifts";
  }

  const status = getChannelStatus(channel, activeChatWindowDays);
  if (["nothing", "closed_after_days", "past"].includes(status)) {
    return "No upcoming shifts";
  }

  const start = moment(channel.shift.start);
  const end = moment(channel.shift.end);

  const until = `until ${end.format("h:mm A")}, ${roundUpHoursTwoDecimal(
    end.diff(start, "hours", true)
  )} hrs`;
  const at = ` ${start.format("h:mm A")}`;

  if (status === "in progress") {
    return `Shift in progress ${until}`;
  }

  let date = "";
  if (status === "today") {
    date = `today ${at}`;
  }
  if (status === "tomorrow") {
    date = `tomorrow, ${start.format("MMM DD")} ${at}`;
  }
  if (status === "ahead") {
    date = `${start.format("MMM DD, YYYY")}, ${at}`;
  }

  return "Next shift: " + date;
}

export function getChannelTitle(channel?: ChatChannel) {
  if (!channel) {
    return;
  }

  const workerName = channel.name.split("-")[1]?.trim();
  return [
    channel.metadata?.hcpName || channel.shift?.agent?.name || workerName,
    channel.metadata?.agentReq,
  ]
    .filter(Boolean)
    .join(", ");
}

export function getMessageInputDefinition({
  isLoadingExclusion,
  sdk,
  actionBy,
}: {
  isLoadingExclusion: boolean;
  sdk?: SdkStore["sdk"];
  actionBy?: ExclusionForList["actionBy"];
}) {
  if (isLoadingExclusion || !sdk) {
    return function () {
      return <></>;
    };
  }

  const disabledMessage = getChannelDisabledMessage(actionBy);

  if (disabledMessage) {
    return function () {
      return <div className="sendbird-disabled-chat">{disabledMessage}</div>;
    };
  }

  // We have to return undefined to render the sendbird message component and using any other value will make the message not visible on the UI.
  return undefined;
}

export function getLastMessageInfo({ lastMessage }, currentUserId: string) {
  if (lastMessage) {
    const from = lastMessage.sender ? (
      <b>{lastMessage.sender.userId === currentUserId ? "You" : lastMessage.sender.nickname}: </b>
    ) : (
      <></>
    );
    if (lastMessage.messageType === "file") {
      return (
        <>
          <FileFilled /> {from} {lastMessage.name}{" "}
        </>
      );
    }

    return (
      <>
        {from} {lastMessage.message}
      </>
    );
  }

  return "No messages";
}

export type CustomTypesChannelFilter = "flex" | "placements" | "all";

interface FetchChannelsProps {
  sdk: SdkStore["sdk"];
  dispatch: Dispatch;
  nicknameContainsFilter?: string;
  customTypesFilter?: CustomTypesChannelFilter;
}

export async function fetchChannels(props: FetchChannelsProps) {
  const { sdk, dispatch, nicknameContainsFilter, customTypesFilter } = props;

  if (!sdk?.groupChannel) {
    return setChannels(dispatch, []);
  }

  let customTypesFilterArray: string[] | undefined;
  if (customTypesFilter && customTypesFilter !== "all") {
    switch (customTypesFilter) {
      case "flex":
        customTypesFilterArray = [""];
        break;
      case "placements":
        customTypesFilterArray = ["placements"];
        break;
    }
  }
  const query = sdk.groupChannel.createMyGroupChannelListQuery({
    includeEmpty: true,
    order: GroupChannelListOrder.LATEST_LAST_MESSAGE,
    limit: CHAT_CHANNEL_QUERY_LIMIT,
    myMemberStateFilter: MyMemberStateFilter.JOINED,
    ...(isDefined(customTypesFilterArray) && { customTypesFilter: customTypesFilterArray }),
    ...(isDefined(nicknameContainsFilter) && { nicknameContainsFilter }),
  });

  const list = await query.next();
  setChannels(dispatch, list);
}
