import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { formatPhone } from "src/utils/strings";
import { Avatar, Stack, useTheme } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Tooltip } from "@mui/material";

import { NearbyWorkerSearchResult, WorkerStatusLabel } from "./useGetNearbyWorkers";
import { ReactElement } from "react";
import { isDefined } from "@clipboard-health/util-ts";
import { useAgentProfilePicture } from "@src/appV2/ShiftBlock/hooks/useGetAgentProfilePicture";
import { useGetATeamConfig } from "@src/appV2/Professionals/MyProfessionals/FeatureFlag/useGetATeamConfig";

interface NearbyWorkerResultProps {
  renderProps: React.HTMLAttributes<HTMLLIElement>;
  nearbyWorker: NearbyWorkerSearchResult;
}

export function NearbyWorkerResult(props: NearbyWorkerResultProps) {
  const { renderProps, nearbyWorker } = props;
  const { workerId, fullName, statusLabel, phoneNumber } = nearbyWorker;
  const { isATeamEnabled } = useGetATeamConfig();
  const theme = useTheme();

  const WORKER_STATUS_ICONS: Record<WorkerStatusLabel, ReactElement> = {
    [WorkerStatusLabel.Favorite]: <StarIcon sx={{ color: "text.secondary" }} />,
    [WorkerStatusLabel.Restricted]: <WarningAmberIcon sx={{ color: "text.secondary" }} />,
    [WorkerStatusLabel.Blocked]: <WarningAmberIcon sx={{ color: "text.secondary" }} />,
  };

  const DISPLAY_STATUS_MAP = {
    [WorkerStatusLabel.Favorite]: isATeamEnabled
      ? undefined
      : { text: "Workplace Favorite", color: "#1890FF" },
    [WorkerStatusLabel.Restricted]: {
      text: "Restricted Account",
      color: theme.palette.warning.main,
    },
    [WorkerStatusLabel.Blocked]: { text: "Blocked Worker", color: theme.palette.error.main },
  };
  const activeDisplayStatus = isDefined(statusLabel) ? DISPLAY_STATUS_MAP[statusLabel] : undefined;

  const { data: agentProfileImage } = useAgentProfilePicture({ agentId: workerId });

  const isBlockedWorker = statusLabel === WorkerStatusLabel.Blocked;

  return (
    <Tooltip
      title="This worker has been blocked by your workplace and cannot be invited"
      disableHoverListener={!isBlockedWorker}
      componentsProps={{
        tooltip: {
          sx: {
            margin: "2px !important",
          },
        },
      }}
    >
      <li
        {...renderProps}
        onClick={(event) => {
          if (isBlockedWorker) {
            event.preventDefault();
            event.stopPropagation();
          } else {
            renderProps.onClick?.(event);
          }
        }}
      >
        <Stack spacing={2} alignItems="center" direction="row">
          <Avatar src={agentProfileImage?.distribution_url}>
            {isDefined(statusLabel) &&
            !(isATeamEnabled && statusLabel === WorkerStatusLabel.Favorite) ? (
              WORKER_STATUS_ICONS[statusLabel]
            ) : (
              <AccountCircleIcon sx={{ color: "text.secondary" }} />
            )}
          </Avatar>
          <Stack>
            <span>
              {fullName}, {formatPhone(phoneNumber)}
            </span>
            {isDefined(activeDisplayStatus) && (
              <Stack sx={{ variant: "body2", color: activeDisplayStatus.color, mt: -0.5 }}>
                {activeDisplayStatus.text}
              </Stack>
            )}
          </Stack>
        </Stack>
      </li>
    </Tooltip>
  );
}
