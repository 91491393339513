import { Button, Modal } from "antd";
import { logout } from "src/modules/session";
import { VerticalSpacing } from "src/designsystem/VerticalSpacing/VerticalSpacing";
import { INDEX_TOP_LEVEL } from "src/designsystem/variables";

export function SuspendedAccountModal({ onClose }: { onClose: () => void }) {
  const handleLogoutClicked = () => {
    logout();
    onClose();
  };

  return (
    <Modal
      title="Account Suspension Notice"
      visible={true}
      closable={false}
      maskClosable={false}
      zIndex={INDEX_TOP_LEVEL}
      footer={
        <Button type="primary" onClick={handleLogoutClicked} data-testid="suspended-modal-logout">
          Log out
        </Button>
      }
      data-testid="suspended-account-modal"
    >
      <p>
        Your account has been suspended which means you can no longer access this workplace's
        profile. For more information contact your workplace admin.
      </p>
      <VerticalSpacing size="md" />
      <p>We kindly ask that you log out of your account. </p>
    </Modal>
  );
}
