export const API_TYPES = {
  placement: "placement",
  workplace: "workplace",
  worker: "worker",
  placementCandidate: "placement-candidate",
  placementApplication: "placement-application",
  placementProspect: "placement-prospect",
  license: "license",
  document: "document",
  credential: "credential",
} as const;
