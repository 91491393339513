// This should be the only place where we have an import from "launchdarkly-js-client-sdk"
// See: https://docs.launchdarkly.com/sdk/client-side/react/react-web#importing-types

// eslint-disable-next-line no-restricted-imports
import { type LDSingleKindContext } from "launchdarkly-js-client-sdk";

export const LD_USER_KIND = "user";

// The purpose of this type is to ensure that we're sending
// the same context to LaunchDarkly with each `identify` call.
interface CbhUserContext extends LDSingleKindContext {
  // Whether the user is an anonymous user.
  anonymous: boolean;
  // User's ID or undefined for anonymous users.
  key?: string;
  // LD kind
  kind: typeof LD_USER_KIND;
  // User's name.
  name: string;
  facilityName: string;
  // User's email.
  email: string;
  // User's metropolitan statistical area.
  msa: string;
  facilitymsa: string;
  // User's pricing metropolitan statistical area.
  pricingMsa: string;
  // User's city address.
  city: string;
  // User's state address.
  state: string;
  facilitystate: string;
  // Facility's type.
  type: string;
  facilityType: string;
  // User's type.
  userType: "WORKPLACE_USER" | "CBH_EMPLOYEE";
}

/** Properties of a signed-in user. */
export interface SignedInUserProperties {
  userId: string;
  name: string;
  email: string;
  msa: string;
  pricingMsa: string;
  city: string;
  state: string;
  stateCode: string;
  type: string;
  hcfWebVersion: string;
  userType: "WORKPLACE_USER" | "CBH_EMPLOYEE";
}

/** Returns the LaunchDarkly context for an anonymous user. */
export function getAnonymousLdUser(): CbhUserContext {
  return {
    anonymous: true,
    // "key" is omitted.
    kind: LD_USER_KIND,
    name: "",
    facilityName: "",
    email: "",
    msa: "",
    facilitymsa: "",
    pricingMsa: "",
    city: "",
    state: "",
    facilitystate: "",
    type: "",
    facilityType: "",
    hcfWebVersion: __APP_VERSION__,
    userType: "WORKPLACE_USER",
  };
}

/** Returns the LaunchDarkly context for a signed-in user. */
export function getSignedInLdUser(properties: SignedInUserProperties): CbhUserContext {
  return {
    anonymous: false,
    key: properties.userId,
    kind: LD_USER_KIND,
    name: properties.name,
    facilityName: properties.name,
    email: properties.email,
    msa: properties.msa,
    facilitymsa: properties.msa,
    pricingMsa: properties.pricingMsa,
    city: properties.city,
    state: properties.state,
    facilitystate: properties.state,
    type: properties.type,
    facilityType: properties.type,
    userType: properties.userType,
    hcfWebVersion: properties.hcfWebVersion,
  };
}
