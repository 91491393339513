import { ShiftTypeWithTime, TimeObject } from "./postShift.types";
import { ShiftType } from "./postShift.schema";
import {
  add,
  addDays,
  addHours,
  addMinutes,
  differenceInHours,
  differenceInMinutes,
  eachDayOfInterval,
  format,
  isAfter,
  isBefore,
  set,
  setHours,
  setMinutes,
} from "date-fns";
import { formatInTimeZone, zonedTimeToUtc } from "date-fns-tz";
import { DefaultShiftTimes } from "src/containers/facilityDashboard/ShiftCalendar/api";
import { getFlooredHourMinuteDuration } from "../facilityDashboard/ShiftCalendar/newShift/shiftDate";
import { RestrictedShiftCreationError } from "./errors";
import { isDefined } from "@clipboard-health/util-ts";
import { AxiosError } from "axios";
import { z } from "zod";

// deviation from standard AM/PM/NOC start time in hours
export const ALLOWED_DEVIATION = 6;

export function getShiftTypeTimes(shiftTime: ShiftTypeWithTime) {
  const startTime = setMinutes(setHours(new Date(), shiftTime.start.hour), shiftTime.start.minute);
  const endTime = addMinutes(
    addHours(startTime, shiftTime.duration.hours),
    shiftTime.duration.minutes
  );

  return { startTime, endTime };
}

export function getShiftSubmitText(count: number): string {
  const prefix = +count === 1 ? "shift" : "shifts";
  return `${count} ${prefix}`;
}

export function calculateDuration(start: Date | null, end: Date | null) {
  if (start && end) {
    // Make sure the start and end are on the same day
    const endWithStartDay = set(end, {
      year: start.getFullYear(),
      month: start.getMonth(),
      date: start.getDate(),
    });

    const adjustedEnd = isBefore(endWithStartDay, start)
      ? addDays(endWithStartDay, 1)
      : endWithStartDay;
    const minutes = differenceInMinutes(adjustedEnd, start);

    return getFlooredHourMinuteDuration(minutes);
  }
}

export function calculateDeviation(
  start: Date | null,
  end: Date | null,
  shiftType: ShiftType,
  shiftTimes?: DefaultShiftTimes
) {
  if (start && end && shiftTimes) {
    const { startTime } = getShiftTypeTimes(shiftTimes[shiftType]);
    const hours = Math.abs(differenceInHours(startTime, start));
    if (hours > 12) {
      return 24 - hours;
    } else {
      return hours;
    }
  } else {
    return 0;
  }
}

export function formatTime(time?: TimeObject) {
  if (!time) {
    return "";
  }
  const { hours, minutes } = time;
  const timeParts: string[] = [];

  if (hours > 0) {
    const formattedHours = String(hours).replace(/^0+/, "");
    const hourString = `${formattedHours} hr${hours !== 1 ? "s" : ""}`;
    timeParts.push(hourString);
  }

  if (minutes > 0) {
    const formattedMinutes = String(minutes).replace(/^0+/, "");
    const minuteString = `${formattedMinutes} min${minutes !== 1 ? "s" : ""}`;
    timeParts.push(minuteString);
  }

  return timeParts.join(" ");
}

interface ObjectWithCount<T> {
  item: T;
  count: number;
}

export function countRepeatedObjects<T>(itemArray: T[], field: keyof T): Array<ObjectWithCount<T>> {
  const countMap = new Map<string, ObjectWithCount<T>>();

  for (const item of itemArray) {
    const fieldValue = String(item[field]);

    if (countMap.has(fieldValue)) {
      const existingObj = countMap.get(fieldValue);
      if (existingObj) {
        existingObj.count++;
      }
    } else {
      const newObj: ObjectWithCount<T> = {
        item,
        count: 1,
      };
      countMap.set(fieldValue, newObj);
    }
  }

  return Array.from(countMap.values());
}

export function getShiftTimesWithTz(
  startDate: Date,
  startTime: Date | null,
  endTime: Date | null,
  tmz: string
) {
  const startDateInUtc = zonedTimeToUtc(
    `${format(startDate, "yyyy-MM-dd")}T${startTime ? format(startTime, "HH:mm") : ""}`,
    tmz
  );
  let endDateInUtc = zonedTimeToUtc(
    `${format(startDate, "yyyy-MM-dd")}T${endTime ? format(endTime, "HH:mm") : ""}`,
    tmz
  );
  const startDateWithTz = formatInTimeZone(startDateInUtc, tmz, "yyyy-MM-dd'T'HH:mm:00XXX");

  if (isBefore(endDateInUtc, startDateInUtc)) {
    endDateInUtc = add(endDateInUtc, { days: 1 });
  }
  const endDateWithTz = formatInTimeZone(endDateInUtc, tmz, "yyyy-MM-dd'T'HH:mm:00XXX");

  return { startDateWithTz, endDateWithTz };
}

export function isShiftStartWithTzBeforeNow(startDate: Date, startTime: Date | null, tmz: string) {
  if (!startTime) {
    return false;
  }

  const startDateInUtc = zonedTimeToUtc(
    `${format(startDate, "yyyy-MM-dd")}T${format(startTime, "HH:mm")}`,
    tmz
  );
  const startDateWithTz = new Date(
    formatInTimeZone(startDateInUtc, tmz, "yyyy-MM-dd'T'HH:mm:00XXX")
  );
  const nowWithTz = new Date(formatInTimeZone(new Date(), tmz, "yyyy-MM-dd'T'HH:mm:00XXX"));
  return isBefore(startDateWithTz, nowWithTz);
}

export function getDatesInRange(
  start: Date | null,
  end: Date | null | undefined,
  daysOfWeek: string[]
): Date[] {
  const dates: Date[] = [];
  if (!start || !end || isAfter(start, end)) {
    return dates;
  }
  const interval = { start, end };
  eachDayOfInterval(interval).forEach((currentDate) => {
    if (daysOfWeek.includes(format(currentDate, "e"))) {
      dates.push(currentDate);
    }
  });
  return dates;
}

export function checkShiftCreationError(error: unknown, type: RestrictedShiftCreationError) {
  return error?.["response"]?.["body"]?.["error"] === type;
}

const newErrorsSchema = z.array(
  z.object({
    status: z.string(),
    code: z.string(),
    title: z.string(),
  })
);

export function extractErrorFromNewApiResponse(error: unknown) {
  if (!(error instanceof AxiosError) || !error.response || !("errors" in error.response.data)) {
    return undefined;
  }

  const parseResult = newErrorsSchema.safeParse(error.response.data.errors);

  return parseResult.success ? parseResult.data : undefined;
}

export function extractErrorFromApiResponse(responseBody?: unknown) {
  if (
    !responseBody ||
    typeof responseBody !== "object" ||
    !("errors" in responseBody) ||
    !Array.isArray(responseBody.errors)
  ) {
    return undefined;
  }

  const errorList = responseBody.errors
    .map((error) => {
      if (!error.status || !error.detail) {
        return undefined;
      }

      return { status: error.status, detail: error.detail };
    })
    .filter((item) => isDefined(item));

  if (errorList.length === 0) {
    return undefined;
  }

  return errorList;
}
