import { Box, Chip, Stack } from "@mui/material";
import { ExternalLink, Text } from "@clipboard-health/ui-react";
import { PLACEMENT_PERKS, PLACEMENT_STATUS } from "../types/createPlacement.schema";
import { getLabel, getQualificationLabel } from "@src/appV2/PlacementCandidates/utils/helper";
import { ReadMore } from "./ReadMore";
import PersonIcon from "@mui/icons-material/Person";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import BusinessIcon from "@mui/icons-material/Business";
import WorkIcon from "@mui/icons-material/Work";
import { formatDollarsAsUsd } from "@src/appV2/lib/Money/utils/currency";
import { PlacementWithDetails } from "../api/useFetchPaginatedPlacements";
import { formatPayRange, getStatusColor } from "../utils/placementDetails";

interface PlacementDetailsProps {
  placement: PlacementWithDetails;
  children?: React.ReactNode;
}

export function PlacementDetails(props: PlacementDetailsProps) {
  const { placement, children } = props;
  const placementStatusLabel =
    PLACEMENT_STATUS.find((status) => status.value === placement.status)?.label ?? "Unknown";

  return (
    <Stack direction="row" gap={3}>
      <Stack flex={2} justifyContent="flex-start" alignItems="flex-start" spacing={2}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Text variant="h4" gutterBottom>
            {placement.title}
          </Text>
          <Chip
            label={placementStatusLabel}
            color={getStatusColor(placement.status)}
            size="small"
            variant="outlined"
          />
        </Stack>
        <Text variant="body2" color="text.primary">
          Qualification: <b>{placement.workerTypes.map(getQualificationLabel).join(", ")}</b>
        </Text>

        {placement.description && (
          <ReadMore
            id="placement-description"
            text={placement.description}
            variant="body2"
            color="text.secondary"
          />
        )}

        <Box marginTop="auto">
          <Stack direction="row" gap={1} flexShrink={0} flexWrap="wrap">
            <Box display="flex" alignItems="center" gap={0.5}>
              <PersonIcon sx={{ fontSize: 16 }} />
              <Text variant="body2">
                Contact: {placement.hiringPOC.name}, {placement.hiringPOC.email}
              </Text>
            </Box>
            {placement.originalJobLink && (
              <Box display="flex" alignItems="center" gap={0.5}>
                <OpenInNewIcon sx={{ fontSize: 16 }} />
                <Text variant="body2">
                  Original at: <ExternalLink to={placement.originalJobLink}>Link</ExternalLink>
                </Text>
              </Box>
            )}
            <Box display="flex" alignItems="center" gap={0.5}>
              <BusinessIcon sx={{ fontSize: 16 }} />
              <Text variant="body2">{placement.workplace.name}</Text>
            </Box>
            <Box display="flex" alignItems="center" gap={0.5}>
              <LocationOnIcon sx={{ fontSize: 16 }} />
              <Text variant="body2">{placement.address.formatted}</Text>
            </Box>
            <Box display="flex" alignItems="center" gap={0.5}>
              <AccessTimeIcon sx={{ fontSize: 16 }} />
              <Text variant="body2">
                {placement.shiftTypes
                  .map((shiftType) => getLabel(shiftType, "shiftType"))
                  .join(", ")}
              </Text>
            </Box>
            <Box display="flex" alignItems="center" gap={0.5}>
              <WorkIcon sx={{ fontSize: 16 }} />
              <Text variant="body2">
                {placement.jobTypes.map((jobType) => getLabel(jobType, "jobType")).join(", ")}
              </Text>
            </Box>
          </Stack>

          {(placement.signOnBonus || placement.perks.length > 0) && (
            <Stack direction="row" mt={2} flexWrap="wrap" gap={1} flexShrink={0}>
              {placement.signOnBonus && (
                <Chip
                  variant="outlined"
                  color="primary"
                  size="small"
                  label={`Sign-on bonus: ${formatDollarsAsUsd(
                    placement.signOnBonus.amountInMinorUnits / 100
                  )}`}
                />
              )}
              {placement.perks.map((perk) => {
                const perkName = PLACEMENT_PERKS.find((p) => p.value === perk)?.label;
                return (
                  perkName && <Chip key={perk} label={perkName} size="small" variant="outlined" />
                );
              })}
            </Stack>
          )}
        </Box>
      </Stack>

      <Stack
        flexDirection="column"
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", md: "flex-end" }}
        gap={1}
        flex={1}
      >
        {children}
        <Text variant="subtitle1" color="primary">
          {formatPayRange(placement.payRange)}
        </Text>
      </Stack>
    </Stack>
  );
}
