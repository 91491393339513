import { isDefined } from "@clipboard-health/util-ts";
import { Session } from "@src/appV2/Auth/types";
import { jobTypeOptions, shiftTypeOptions } from "./constant";
import { ShiftNameType, JobType } from "../types";
import { startCase } from "lodash";

export function getUserType(profile: Session["profile"], admin: boolean) {
  if (!isDefined(profile)) {
    return "GUEST_EMAIL";
  }
  return admin ? "CBH_EMPLOYEE" : "WORKPLACE_USER";
}

export function getQualificationLabel(qualification: string) {
  if (qualification === "LVN") {
    return "LVN/LPN";
  }
  if (qualification.length <= 3) {
    return qualification;
  }
  return qualification.toLowerCase().split(" ").map(startCase).join(" ");
}

export function getLabel(value: string, type: "jobType" | "shiftType"): string {
  const options = type === "jobType" ? jobTypeOptions : shiftTypeOptions;
  const option = options.find((opt) => opt.value === value);
  return option ? option.label : value;
}

const SHIFT_TYPE_ORDER = [ShiftNameType.AM, ShiftNameType.PM, ShiftNameType.NOC] as const;
type OrderedShiftType = typeof SHIFT_TYPE_ORDER[number];

// Define the order for job types
const JOB_TYPE_ORDER = [JobType.FULL_TIME, JobType.PART_TIME, JobType.PER_DIEM] as const;
type OrderedJobType = typeof JOB_TYPE_ORDER[number];

export function sortAndLabelShiftTypes(
  shiftTypes: string[]
): { type: OrderedShiftType; label: string }[] {
  const validShiftTypes = shiftTypes.filter((type): type is OrderedShiftType =>
    SHIFT_TYPE_ORDER.includes(type as OrderedShiftType)
  );

  return SHIFT_TYPE_ORDER.filter((type) => validShiftTypes.includes(type)).map((type) => ({
    type,
    label: getLabel(type, "shiftType"),
  }));
}

export function sortAndLabelJobTypes(
  jobTypes: string[]
): { type: OrderedJobType; label: string }[] {
  const validJobTypes = jobTypes.filter((type): type is OrderedJobType =>
    JOB_TYPE_ORDER.includes(type as OrderedJobType)
  );

  return JOB_TYPE_ORDER.filter((type) => validJobTypes.includes(type)).map((type) => ({
    type,
    label: getLabel(type, "jobType"),
  }));
}
