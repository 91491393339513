import { Text } from "@clipboard-health/ui-react";
import { Box, Card, CardContent, Stack, SxProps, Theme } from "@mui/material";
import { type ReactNode } from "react";

interface CbhCustomChatMessageProps {
  message: string;
  children?: ReactNode;
  avatarSxProps?: SxProps<Theme>;
  messageContainerSxProps?: SxProps<Theme>;
}

export function CbhCustomChatMessage(props: CbhCustomChatMessageProps) {
  const { message, children, avatarSxProps, messageContainerSxProps } = props;

  return (
    <Stack direction="row" spacing={1} marginBottom={2} sx={{ ...messageContainerSxProps }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          flexDirection: "column",
          ...avatarSxProps,
        }}
      >
        <img src="/bunnyB.png" alt="" width={32} height={32} />
      </Box>
      <Stack>
        <Text>Clipboard Health</Text>
        <Card>
          <CardContent
            sx={{
              "&:last-child": {
                paddingBottom: 2,
              },
            }}
          >
            <Text
              variant="body2"
              sx={{
                whiteSpace: "pre-wrap",
              }}
            >
              {message}
            </Text>
          </CardContent>
          {children}
        </Card>
      </Stack>
    </Stack>
  );
}
