const HCF_DEFAULT_YAML_VALUE = `hcf:
    name: Test Workplace
    email: testworkplace@gmail.com
    phone: 2025550169
    address:
        streetNumber: "400"
        streetName: "Oak Street"
        city: "San Francisco"
        region: "San Francisco County"
        state: "California"
        stateCode: "CA"
        country: "United States"
        countryCode: "US"
        postalCode: "94102"
        formatted: "400 Oak St, San Francisco, CA 94102, USA"
    geoLocation:
        coordinates: [ -122.426094,37.77478199999999 ]
        type: "Point"
    tmz:  America/Los_Angeles
    type: Home Healthcare
    rates:
        CNA: 20
        LVN: 20
        RN: 20
        NP: 20
        CAREGIVER: 20
        Medical Technician: 21`;

export { HCF_DEFAULT_YAML_VALUE };
