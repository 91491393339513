import { Text } from "@clipboard-health/ui-react";
import { TextVariant } from "@clipboard-health/ui-react/src/Typography/Text/Text";
import { Link } from "@mui/material";
import { useState } from "react";

interface ReadMoreProps {
  id: string;
  text: string;
  amountOfWords?: number;
  variant?: TextVariant;
  color?: string;
}

export const ReadMore = (props: ReadMoreProps) => {
  const { id, text, amountOfWords = 50, variant, color } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const splitText = text.split(" ");
  const textCanOverflow = splitText.length > amountOfWords;
  const beginText = textCanOverflow ? splitText.slice(0, amountOfWords - 1).join(" ") : text;
  const endText = splitText.slice(amountOfWords - 1).join(" ");
  return (
    <Text id={id} variant={variant} color={color}>
      {beginText}
      {textCanOverflow && (
        <>
          {!isExpanded && <span>...</span>}
          <span style={{ display: !isExpanded ? "none" : "inline" }} aria-hidden={!isExpanded}>
            {" "}
            {endText}
          </span>
          <Link
            component="button"
            aria-expanded={isExpanded}
            aria-controls={id}
            onClick={() => setIsExpanded(!isExpanded)}
            sx={{ ml: 0.25 }}
          >
            {isExpanded ? "show less" : "show more"}
          </Link>
        </>
      )}
    </Text>
  );
};
