import { RumInitConfiguration, datadogRum } from "@datadog/browser-rum";
import { environmentConfig, isProductionNodeEnvironment } from "@src/appV2/environment";
import { SessionType } from "src/modules/interface";

const datadogRumInitConfig: RumInitConfiguration = {
  applicationId: environmentConfig.REACT_APP_ADMIN_DATADOG_RUM_APPLICATION_ID,
  clientToken: environmentConfig.REACT_APP_ADMIN_DATADOG_RUM_CLIENT_TOKEN,
  service: "admin-web-app",
  defaultPrivacyLevel: "mask-user-input",
  version: __APP_VERSION__ ?? "1.0.0",
  sampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  env: environmentConfig.REACT_APP_ENVIRONMENT_NAME,
  allowedTracingOrigins: [environmentConfig.REACT_APP_API_MAIN_URL],
  allowedTracingUrls: [
    /^https:\/\/.*\.prod\.clipboardhealth\.org/,
    /^https:\/\/.*\.clipboard\.health/,
  ],
  enableExperimentalFeatures: ["feature_flags"],
  excludedActivityUrls: [/getwarmly/],
};

export const initDatadogRum = () => {
  datadogRum.init(datadogRumInitConfig);

  if (isProductionNodeEnvironment()) {
    datadogRum.init({
      ...datadogRumInitConfig,
      beforeSend(event) {
        if (event.context) {
          event.context.screenWidth = window.screen.width;
          event.context.screenHeight = window.screen.height;
        }
        return true;
      },
    });
  }
};

export const removeDatadogRUMUser = () => {
  datadogRum.removeUser();
};

export const identifyDatadogRumUser = (session: SessionType["session"]) => {
  const userProperties = getUserProperties(session);

  datadogRum.setUser({
    // As facility user names are not being searched for
    // we keep the facility's name instead of facility user's name for backward compatible
    name: session.profile?.name,
    email: session.user?.email,
    ...userProperties,
  });
};

export function setDatadogUserProperty(key: string, value: unknown) {
  datadogRum.setUserProperty(key, value);
}

function getUserProperties(session: SessionType["session"]) {
  if (session?.adminProfile) {
    return {
      type: "ADMIN",
      id: session.user?._id,
      sessionType: session.type,
      ...(session.type === "FACILITY"
        ? {
            facilityId: session.profile?.userId,
          }
        : session.type === "AGENT"
        ? {
            agentId: session.profile?.userId,
          }
        : {
            adminId: session.profile?.userId,
          }),
    };
  } else {
    return {
      type: "FACILITY",
      id: session.user?._id,
      facilityId: session.profile?.userId,
    };
  }
}
