import { Menu } from "antd";
import { AccountSwitcherOptionGroup } from "./AccountSwitcherOptionGroup";
import { Button } from "@mui/material";

export function AccountSwitcherMenu({ handleChange, adminUserId, agentDetails, facilityDetails }) {
  return (
    <Menu style={{ padding: ".75rem 0 0 1rem" }} data-testid="account-switcher-menu">
      <Button
        variant="outlined"
        sx={{ fontWeight: "bold", paddingY: 2, marginBottom: 2 }}
        onClick={() => handleChange({ user: { userId: adminUserId }, type: "ADMIN" })}
      >
        <b>Switch To Administrator →</b>
      </Button>
      <div className="account-groups-list">
        <div data-testid="account-switcher-agents">
          <h4 className="account-group">Agent</h4>
          <div className="accounts-list">
            <AccountSwitcherOptionGroup
              type="AGENT"
              userDetails={agentDetails}
              handleChange={handleChange}
            />
          </div>
        </div>
        <div data-testid="account-switcher-facilities">
          <h4 className="account-group">Workplace</h4>
          <div className="accounts-list">
            <AccountSwitcherOptionGroup
              type="FACILITY"
              userDetails={facilityDetails}
              handleChange={handleChange}
            />
          </div>
        </div>
      </div>
    </Menu>
  );
}
